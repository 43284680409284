import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import Header from './components/Header'
import Aside from './components/Aside'
import md5 from 'js-md5'
import bus from './js/bus'
import axios from 'axios'
import api from './api/'
import echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icon/iconfont.css';

//配置全局组件
Vue.component('my-header',Header)
Vue.component('my-aside',Aside)

Vue.use(ElementUI);

//配置axios
Vue.prototype.$axios = axios

//配置Bus
Vue.prototype.$bus = bus

//配置md5
Vue.prototype.$md5 = md5
 
//配置api
Vue.prototype.$api = api

//配置echarts
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')