<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="params.type === 0" :to="{ path: '/cateExpend' }">支出分类</el-breadcrumb-item>
      <el-breadcrumb-item v-else :to="{ path: '/cateIncome' }">收入分类</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card :body-style="{ padding: '0px' }" shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>收支分类</span>
      </div>
      <el-form ref="form" class="info_body" :model="params">
        <el-form-item label="分类名称" class="info_item">
          <el-input v-model="params.name" class="info_input" placeholder="分类名称" />
        </el-form-item>
        <el-form-item label="分类编码" class="info_item">
          <el-input v-model="params.number" class="info_input" placeholder="分类编码"/>
        </el-form-item>
        <el-form-item label="排序" class="info_item">
          <el-input-number class="info_input" v-model="params.seq" :step="1" />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="returnPage">返回</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      params: {}
    }
  },
  methods: {
    onSubmit() {
      this.$confirm('是否确定修改?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          id: this.params.id,
          pid: this.params.pid,
          cateName: this.params.name,
          cateNum: this.params.number,
          userId: this.params.userId,
          seq: this.params.seq,
          type: this.params.type
        };
        this.$api.cate.cateEdit(param).then(res => {
          if (res.code == 0) {
            this.$message({ type: 'success', message: '保存成功' });
            setTimeout(() => { this.$router.go(-1) }, 1000);
          }
        });
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消' });
      });
    },
    returnPage() {
      this.$router.go(-1);
    }
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.params = this.$route.params;
  }
}
</script>