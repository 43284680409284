import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id:'',
    userName:'',
    trueName:'',
    phone:'',
    gender:'',
    email:'',
    isLogined:false,
    baseUrl: 'http://43.153.94.45/',
    // baseUrl: 'http://wwww.cheham.asia/',
    wsUrl: 'ws://10.10.1.26:8080/adam_notify/websocket/iot/',
    headers:{
      'X-User-Agent':'HAMBOOK',
      'X-User-Platform':'web'
    }
  },
  mutations: {
    //设置当前登录用户信息
    loginedMutations(state, payload){
      state.id = payload.id;
      state.userName = payload.userName;
      state.trueName = payload.trueName;
      state.phone = payload.phone;
      state.gender = payload.gender;
      state.email = payload.email;
      state.isLogined = true;
    },
    //清除当前登录用户信息
    logoutMutations(state){
      state.id = '';
      state.userName = '';
      state.trueName = '';
      state.phone = '';
      state.gender = '';
      state.email = '';
      state.isLogined = false;
    },
    //设置请求头
    setReqHeadersMutations(state, payload){
      state.headers[payload.label] = payload.value;
    }
  },
  actions: {
  },
  modules: {
  }
})
