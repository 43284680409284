<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/huabei'}">蚂蚁花呗</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card :body-style="{ padding: '0px' }" shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>蚂蚁花呗</span>
      </div>      
      <el-form ref="form" class="info_body" :model="params">
        <el-form-item label="花呗名称" class="info_item">
          <el-input v-model="params.accName" class="info_input" placeholder="花呗名称" />
        </el-form-item>
        <el-form-item label="支付宝账号" class="info_item">
          <el-input v-model="params.accNum" class="info_input" placeholder="支付宝账号" />
        </el-form-item>
        <el-form-item label="信用额度" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.creditLines" :precision="2" :step="0.1" :max="1000000" />
        </el-form-item>
        <el-form-item label="可用额度" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.leftAmount" :precision="2" :step="0.1" :max="1000000" />
        </el-form-item>
        <el-form-item label="已出账单" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.repayAmount" :precision="2" :step="0.1" :max="1000000" />
        </el-form-item>
        <el-form-item label="距出账日" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.repaymentDate" :step="1" :max="30" />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="returnPage">返回</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      params: {}
    };
  },
  methods: {
    onSubmit() {
      this.$confirm("是否确定修改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.user.huabeiEdit(this.params).then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: "保存成功" });
            setTimeout(() => {
              this.returnPage();
            }, 500);
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    },
    returnPage() {
      this.$router.push({
        path: "/huabei",
        name: "Huabei"
      });
    },
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.params = this.$route.params;
  },
};
</script>