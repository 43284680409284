<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>蚂蚁花呗</el-breadcrumb-item>
    </el-breadcrumb>
    <el-input v-model="filterText" class="table_bar_item fixed_input" @keyup.enter.native="getList" placeholder="请输入模糊内容" clearable>
      <el-button slot="append" @click="getList" class="icon_button" icon="el-icon-search" />
    </el-input>
    <el-table :data="tableData" stripe max-height="590">
      <el-table-column type="selection" width="50" fixed />
      <el-table-column type="index" label="#" width="50" />
      <el-table-column prop="accName" label="花呗名称" show-overflow-tooltip />
      <el-table-column prop="accNum" label="支付宝账号" show-overflow-tooltip />
      <el-table-column prop="creditLines" label="总额度" />
      <el-table-column prop="leftAmount" label="可用额度" />
      <el-table-column prop="repayDate" label="本期还款日" />
      <el-table-column prop="repayAmount" label="待还款" />
      <el-table-column prop="daysToRepayment" label="还款倒计时">
        <template slot-scope="scope">
          <el-tag size="medium" type="success">{{ scope.row.daysToRepayment }} 天</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">关闭</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="totalRows" :page-size="size" :current-page.sync="page" @current-change="doPage" @size-change="doSize" :page-sizes="[5, 10, 20]" />
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      size: 10,
      filterText: "",
      totalPage: 0,
      totalRows: 0,
      tableData: []
    }
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.getList();
  },
  methods: {
    doPage(currentPage) {
      this.page = currentPage;
      this.getList();
    },
    doSize(currentSize) {
      this.page = 1;
      this.size = currentSize;
      this.getList();
    },
    getList() {
      let params = {
        page: this.page,
        num: this.size,
      };
      if (this.filterText) params.filterText = this.filterText;
      this.$api.user.huabeiPage(params).then(res => {
        if (res.data) {
          this.tableData = res.data.list;
          this.tableData.forEach(item => {
            item.creditLines = item.creditLines.toFixed(2);
            item.leftAmount = item.leftAmount.toFixed(2);
            item.repayAmount = item.repayAmount.toFixed(2);
          });
        }
      });
    },
    handleDelete(index, row) {
      this.$confirm("此操作将关闭" + row.accName + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.doDel(row.id);
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    },
    doDel(id) {
      this.$api.user.huabeiDel(id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: "关闭成功" });
          this.getList();
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: "/huabeiDetail",
        name: "HuabeiDetail",
        params: row,
      });
    }
  }
}
</script>