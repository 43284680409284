import { get } from '../axios/axios.js'
export default {
    reportBroken(){
        return get('/adam_book/report/broken')
    },
    reportColumnar(){
        return get('/adam_book/report/columnar')
    },
    reportAnnular(){
        return get('/adam_book/report/annular')
    }
}