<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>个人中心</span>
      </div>
      <el-avatar shape="square" :size="110" :src="form.avatar" class="avatar"></el-avatar>
      <el-form ref="form" class="info_body" :model="form" :rules="rules">
        <el-form-item label="姓名" prop="name" class="info_item">
          <el-input v-model="form.name" placeholder="请输入姓名" class="info_input" clearable />
        </el-form-item>
        <el-form-item label="用户名" class="info_item">
          <el-input v-model="form.account" placeholder="请输入用户名" class="info_input" clearable disabled />
        </el-form-item>
        <el-form-item label="手机号" prop="account" class="info_item">
          <el-input v-model="form.account" placeholder="请输入手机号" class="info_input" clearable />
        </el-form-item>
        <el-form-item label="性别" class="info_item">
          <el-select v-model="form.gender" class="info_input info_role">
            <el-option v-for="(item,index) in sexs" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" class="info_item">
          <el-input v-model="form.email" placeholder="请输入邮箱" class="info_input" clearable />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="returnBack">返回</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      sexs: [{ label: '男', value: true }, { label: '女', value: false }],
      rules: {
        name: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
          { min: 2, max: 4, message: "姓名长度为2-4个字符", trigger: "blur" },
        ],
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          { pattern: /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/, message: '请输入合法的邮箱', trigger: 'blur' }
        ],
        account: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { pattern: /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/, message: '请输入合法的手机号', trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    let info = localStorage.getItem('UserInfo');
    this.form = JSON.parse(info);
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
  },
  methods: {
    returnBack() {
      this.$router.push('/index');
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.doSave();
        } else {
          return false;
        }
      });
    },
    doSave() {
      this.$confirm("是否确定提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.user.userUpd(this.form).then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: "保存成功" });
            localStorage.setItem("UserInfo", JSON.stringify(this.form));
            localStorage.setItem("TrueName", this.form.name);
            this.$bus.$emit('myMsg', this.form.name);
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    }
  }
}
</script>
<style scoped>
.info_card {
  padding: 15px;
}
.avatar{
  float: left;
  position: relative;
  top: 29px;
  left: 5px;
}
.info_card:not(:last-child) {
  /* height: 189px; */
  margin-bottom: 20px;
}
.info_card >>> .el-card__body {
  padding: 0;
}
.info_header span {
  line-height: 45px;
}
.info_body {
  position: relative;
  left: 15px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.info_item {
  width: 33%;
  display: block;
}
.info_input {
  float: left;
  width: 255px;
}
.info_item >>> .el-form-item__error {
  margin-left: 55px;
}
</style>