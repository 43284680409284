import { get, post } from '../axios/axios.js'
export default {
    login(params) {
        return post('/adam_user/user/login', params)
    },
    logout() {
        return get('/adam_user/user/logout')
    },
    userInfo() {
        return get('adam_user/user/info')
    },
    creditPage(params) {
        return post('/adam_book/creditPage', params)
    },
    creditCancel(params) {
        return get('/adam_book/creditCancel/'+params)
    },
    creditEdit(params) {
        return post('/adam_book/creditUpdate', params)
    },
    huabeiPage(params) {
        return post("/adam_book/huabeiPage", params)
    },
    huabeiEdit(params) {
        return post("/adam_book/huabeiUpdate", params)
    },
    huabeiDel(params) {
        return get('/adam_book/huabeiDel/'+params)
    },
    userUpd(params) {
        return post('/adam_user/user/update', params)
    },
    userOrgs(params) {
        return get('/adam_user/user/orgs/' + params)
    },
    userPwd(params) {
        return post('/adam_user/user/modify/pwd', params)
    }
}