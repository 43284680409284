<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>信用卡中心</el-breadcrumb-item>
    </el-breadcrumb>
    <el-input v-model="filterText" class="table_bar_item fixed_input" placeholder="请输入模糊内容" clearable>
      <el-button slot="append" @click="doFilter" class="icon_button" icon="el-icon-search" />
    </el-input>
    <el-table :data="tableData" class="info_table" @row-click="onClick" stripe>
      <el-table-column type="selection" width="50" fixed />
      <el-table-column type="index" label="#" width="50" />
      <el-table-column prop="image" label="银行">
        <template slot-scope="scope">
          <el-upload ref="image" accept=".png,.jpg,jpeg" action="#" :http-request="uploadImg" :auto-upload="true" :before-upload="beforeUploadImg" :show-file-list="false">
            <el-image class="info_image" :src="scope.row.bank" fit="fill" />
          </el-upload>
        </template>
      </el-table-column>
      <el-table-column prop="cardName" label="卡片名称" show-overflow-tooltip />
      <el-table-column prop="cardNum" label="卡号" show-overflow-tooltip />
      <el-table-column prop="creditLines" label="信用额度" />
      <el-table-column prop="leftAmount" label="可用额度">
        <template slot-scope="scope">
          <div :style="{'color':'red'}">{{scope.row.leftAmount}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="billDate" label="本期账单日" />
      <el-table-column prop="repayDate" label="本期还款日" />
      <el-table-column prop="repayAmount" label="已出账单" >
        <template slot-scope="scope">
          <div v-if="scope.row.repayAmount > 0" :style="{'color':'red'}">{{scope.row.repayAmount}}</div>
          <div v-else>{{scope.row.repayAmount}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="unpaidBill" label="未出账单" >
        <template slot-scope="scope">
          <div v-if="scope.row.unpaidBill > 0" :style="{'color':'red'}">{{scope.row.unpaidBill}}</div>
          <div v-else>{{scope.row.unpaidBill}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="daysToRepayment" label="还款倒计时" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.daysToRepayment == 0" size="medium" type="danger">今天到期</el-tag>
          <el-tag v-else-if="scope.row.daysToRepayment == 1" size="medium" type="danger">明天到期</el-tag>
          <el-tag v-else-if="scope.row.daysToRepayment == 31" size="medium" type="success">无需还款</el-tag>
          <el-tag v-else size="medium" type="danger">{{ scope.row.daysToRepayment }} 天</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">注销</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="totalRows" :page-size="size" :current-page.sync="page" @current-change="doPage" @size-change="doSize" :page-sizes="[5, 10, 20]" />
  </el-main>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      filterText: "",
      page: 1,
      size: 20,
      totalPage: 0,
      totalRows: 0,
      tableData: [],
      creditId: "",
      imgFile: new FormData()
    };
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    let param = this.$route.params;
    let params = {
      page: this.page,
      num: this.size,
    };
    if (param.filterText) params.filterText = param.filterText;
    this.doAxios(params);
  },
  computed: {
    ...mapState(['baseUrl'])
  },
  methods: {
    init() {
      this.page = 1;
      this.getList();
    },
    doAxios(params) {
      this.tableData = [];
      this.$api.user.creditPage(params).then((res) => {
        if (res.code == 0) {
          this.totalPage = res.data.totalPage;
          this.totalRows = res.data.totalNum;
          if (this.totalPage > 0) {
            this.tableData = res.data.list;
            this.tableData.forEach((item) => {
              item.creditLines = item.creditLines.toFixed(2);
              item.leftAmount = item.leftAmount.toFixed(2);
              item.repayAmount = item.repayAmount.toFixed(2);
              item.unpaidBill = item.unpaidBill.toFixed(2);
            });
          }
        }
      });
    },
    beforeUploadImg(file) {
      this.imgFile = new FormData();
      this.imgFile.append("file", file);
      return true;
    },
    onClick(row) {
      this.creditId = row.id;
    },
    uploadImg() {
      this.imgFile.append("creditId", this.creditId);
      this.loading = true;
      this.$axios({
        url: this.baseUrl+"adam_disk/upload/bank",
        method: "post",
        data: this.imgFile,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        if (res.data.code == 0) {
          setTimeout(() => {
            this.loading = false;
            this.$message({ type: "success", message: "图片上传成功" });
            this.getList();
          }, 1200);
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: "/creditDetail",
        name: "CreditDetail",
        params: row,
      });
    },
    handleDelete(index, row) {
      this.$confirm("此操作将注销" + row.cardName + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.doDel(row.id);
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    },
    doDel(id) {
      this.$api.user.creditCancel(id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: "注销成功" });
          this.getList();
        }
      });
    },
    doFilter() {
      this.getList();
    },
    doPage(currentPage) {
      this.page = currentPage;
      this.getList();
    },
    doSize(currentSize) {
      this.page = 1;
      this.size = currentSize;
      this.getList();
    },
    setParams() {
      let params = {
        page: this.page,
        num: this.size,
      };
      if (this.filterText) params.filterText = this.filterText;
      if (this.type !== "") params.type = this.type + 1;
      if (this.status) params.status = this.status;
      if (this.switchOn) params.switchOn = this.switchOn;
      return params;
    },
    getList() {
      let params = this.setParams();
      this.doAxios(params);
    },
  },
};
</script>
<style scoped>
.info_table >>> td {
  padding: 0 !important;
}
.info_image {
  width: 40px;
  height: 40px;
  top: 4px;
}
</style>