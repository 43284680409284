<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>明细流水</el-breadcrumb-item>
    </el-breadcrumb>
    <el-select v-model="flag" class="table_bar_item" placeholder="请选择标签" clearable>
      <el-option v-for="(item, index) in flagArr" :key="index" :label="item" :value="index" />
    </el-select>
    <!-- 级联选择器(支出) -->
    <el-cascader v-if="flag === 0" placeholder="请选择支出分类" class="table_bar_item" v-model="category" :options="expendArr" :props="{checkStrictly:true}" clearable />
    <!-- 级联选择器(收入) -->
    <el-cascader v-if="flag === 1" placeholder="请选择收入分类" class="table_bar_item" v-model="category" :options="incomeArr" :props="{checkStrictly:true}" clearable />
    <!-- select选择器(借贷) -->
    <el-select v-else-if="flag == 3" v-model="category" class="table_bar_item" placeholder="请选择借贷标签" clearable>
      <el-option v-for="(item, index) in loanArr" :key="index" :label="item" :value="index" />
    </el-select>
    <el-select v-model="account" class="table_bar_item" placeholder="请选择账户" clearable>
      <el-option v-for="item in accounts" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <el-date-picker v-model="timeRange" class="table_bar_item data_picker" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="defaultTime" :picker-options="pickerOptions" />
    <el-input v-model="filterText" class="table_bar_item fixed_input" placeholder="请输入模糊内容" clearable>
      <el-button slot="append" @click="filterQuery" class="icon_button" icon="el-icon-search" />
    </el-input>
    <el-button v-if="totalMoney" plain type="info" class="add_btn" disabled>{{totalMoney}}</el-button>
    <!-- 主Table -->
    <el-table :data="tableData" ref="table" tooltip-effect="dark" stripe @selection-change="handleSelectionChange" @cell-dblclick="tabClick" max-height="590">
      <el-table-column type="selection" width="50" fixed />
      <el-table-column type="index" label="#" width="50" />
      <el-table-column prop="chargeTime" label="事件时间" width="220">
        <template slot-scope="scope">
          <span v-if="scope.row.id === tabClickId && tabClickProp === 'chargeTime'">
            <el-date-picker class="my_cas" v-focus v-model="scope.row.chargeTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" @blur="dateBlur(scope.row)" @change="dateChange(scope.row)" placeholder="选择日期时间" />
          </span>
          <span v-else>{{ scope.row.chargeTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="flag" label="标签" />
      <el-table-column prop="categoryName" label="类别">
        <template slot-scope="scope">
          <span v-if="scope.row.id === tabClickId && tabClickProp === 'categoryName' && flag == 0">
            <el-cascader class="my_cas" v-model="scope.row.categoryId" v-focus @change="casChange(scope.row)" :options="expendArr" :props="{checkStrictly:false}" />
          </span>
          <span v-else-if="scope.row.id === tabClickId && tabClickProp === 'categoryName' && flag == 1">
            <el-cascader class="my_cas" v-model="scope.row.categoryId" v-focus @change="casChange(scope.row)" :options="incomeArr" :props="{checkStrictly:false}" />
          </span>
          <span v-else>{{ scope.row.categoryName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="chargeNum" label="金额" />
      <el-table-column prop="accountName" label="账号" width="370" show-overflow-tooltip />
      <el-table-column prop="remark" label="备注" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.id === tabClickId && tabClickProp === 'remark'">
            <el-input v-model="scope.row.remark" size="mini" v-focus @blur="inputBlur(scope.row)" clearable />
          </span>
          <span v-else>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="totalRows" :page-size="size" :current-page.sync="page" @current-change="doPage" @size-change="doSize" :page-sizes="[5, 10, 20]" />
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      size: 10,
      flag: 0,
      account: "",
      category: "",
      expendArr: [],
      incomeArr: [],
      timeRange: [],
      totalRows: 0,
      filterText: "",
      totalMoney: "",
      tabClickId: "",
      tabClickProp: "", // 当前点击的列名
      tabClickData: "",
      loading: false,
      tableData: [],
      multipleSelection: [],
      defaultTime: ['00:00:00', '23:59:59'],
      flagArr: ["支出", "收入", "转账", "借贷"],
      loanArr: ["借入", "借出", "还款", "收款"],
      accounts: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    let params = this.$route.params;
    if (!params.timeRange) return;
    var temp = this.getLastDay(params.timeRange);
    switch (params.type) {
      case 'broken':
        let year = new Date().getFullYear();
        this.timeRange[0] = year + '-' + params.timeRange + ' 00:00:00';
        this.timeRange[1] = year + '-' + params.timeRange + ' 23:59:59';
        break;
      case 'columnar':
        this.timeRange[0] = params.timeRange + '-01 00:00:00';
        this.timeRange[1] = params.timeRange + '-' + temp + ' 23:59:59';
        break;
      default:
        break;
    }
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.getAccounts();
    this.getCategory();
    this.getList();
    this.getCount();
  },
  watch: {
    flag() {
      this.category = "";
      setTimeout(() => {
        this.init();
      }, 100);
    },
    loanType() {
      this.init();
    },
    category() {
      this.init();
    },
    account() {
      this.init();
    },
    timeRange() {
      this.init();
    }
  },
  //input设置focus失败的解决办法
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector("input").focus();
      },
    },
  },
  methods: {
    tabClick(row, column, cell, event) {
      switch (column.property) {
        case "chargeTime":
        case "categoryName":
        case "remark":
          this.tabClickId = row.id;
          this.tabClickProp = column.property;
          this.tabClickData = row[this.tabClickProp];
          break;
        default:
          return;
      }
    },
    getLastDay(date) {
      var arr = date.split('-');
      var year = arr[0];
      var month = arr[1];
      var new_year = year;
      var new_month = month++;
      if (month > 12) {
        new_month -= 12;
        new_year++;
      }
      var new_date = new Date(new_year, new_month, 1);
      return (new Date(new_date.getTime() - 1000 * 60 * 60 * 24)).getDate();
    },
    inputBlur(row) {
      if (this.tabClickData == row[this.tabClickProp]) {
        this.casBlur();
        return;
      }
      this.$api.flow.flowEdit(row).then((res) => {
        if (res.code == 0) {
          this.casBlur();
          this.success();
        }
      });
    },
    dateBlur(row) {
      this.casBlur();
    },
    casChange(row) {
      row.categoryId = row.categoryId.slice(-1)[0];
      this.$api.flow.flowEdit(row).then((res) => {
        if (res.code == 0) {
          this.casBlur();
          this.success();
        }
      });
    },
    dateChange(row) {
      this.$api.flow.flowEdit(row).then((res) => {
        if (res.code == 0) {
          this.casBlur();
          this.success();
        }
      });
    },
    casBlur() {
      this.tabClickId = "";
      this.tabClickProp = "";
      this.tabClickData = "";
    },
    doPage(currentPage) {
      this.page = currentPage;
      this.getList();
    },
    doSize(currentSize) {
      this.page = 1;
      this.size = currentSize;
      this.getList();
    },
    init() {
      this.page = 1;
      this.getList();
      this.getCount();
    },
    success() {
      this.getList();
      this.getCount();
    },
    filterQuery() {
      this.getList();
      this.getCount();
    },
    getCategory() {
      this.$api.cate.cateCascade(0).then((res) => {
        if (res.code == 0) {
          this.expendArr = res.data;
        }
      });
      this.$api.cate.cateCascade(1).then((res) => {
        if (res.code == 0) {
          this.incomeArr = res.data;
        }
      });
    },
    getAccounts() {
      this.$api.acc.accSelect().then((res) => {
        if (res.code == 0) {
          this.accounts = res.data;
        }
      });
    },
    getCount() {
      let param = {};
      if (this.filterText) param.filterText = this.filterText;
      if (this.flag !== "") param.flag = this.flag + 1;
      if (this.account !== "") param.accountId = this.account;
      if (this.timeRange && this.timeRange.length == 2) param.timeRange = this.timeRange;
      if (this.category !== "") {
        switch (this.flag) {
          case 0:
          case 1:
            param.categoryId = this.category.slice(-1)[0];
            break;
          case 3:
            param.categoryId = this.category + 1;
          default:
            break;
        }
      }
      if (!param) return;
      this.$api.flow.flowCount(param).then((res) => {
        if (res.code == 0) {
          this.totalMoney = "￥" + res.data.toFixed(2);
        }
      });
    },
    getList() {
      this.loading = true;
      this.tableData = [];
      this.totalRows = 0;
      let param = {
        page: this.page,
        num: this.size,
      };
      if (this.filterText) param.filterText = this.filterText;
      if (this.flag !== "") param.flag = this.flag + 1;
      if (this.account !== "") param.accountId = this.account;
      if (this.timeRange && this.timeRange.length == 2) param.timeRange = this.timeRange;
      if (this.category !== "") {
        switch (this.flag) {
          case 0:
          case 1:
            param.categoryId = this.category.slice(-1)[0];
            break;
          case 3:
            param.categoryId = this.category + 1;
          default:
            break;
        }
      }
      this.$api.flow.flowPage(param).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          let data = res.data;
          this.page = data.page;
          this.size = data.num;
          this.totalPage = data.totalPage;
          if (this.totalPage > 0) {
            this.totalRows = data.totalNum;
            let array = data.list;
            array.forEach((item) => {
              item.flag = this.flagArr[item.flag - 1];
              item.chargeNum = item.chargeNum.toFixed(2);
              this.tableData.push(item);
            });
          }
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api.flow.flowDel(row.id).then(res => {
          if (res.code == 0) {
            this.$message({ type: 'success', message: '删除成功' });
            this.init();
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "已取消删除" });
      });
    },
  },
};
</script>
<style>
.charge_time {
  margin-left: 10px;
}
.flag_select {
  width: 160px !important;
}
.data_picker {
  width: 400px !important;
}
.my_cas {
  line-height: 28px !important;
}
.my_cas .el-input__icon {
  line-height: 28px !important;
}
.my_cas .el-input__inner {
  height: 28px !important;
}
</style>