<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/account', params:params, name:'Account' }">账户管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card :body-style="{ padding: '0px' }" shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>编辑账户</span>
      </div>
      <el-form ref="form" class="info_body" :model="params">
        <el-form-item label="账户名" class="info_item">
          <el-input class="info_input" v-model="params.name" placeholder="账户名" />
        </el-form-item>
        <el-form-item label="账号" class="info_item">
          <el-input class="info_input" v-model="params.account" placeholder="账号" />
        </el-form-item>
        <el-form-item label="账户类型" class="info_item">
          <el-select class="info_input" v-model="indexType" placeholder="账户类型">
            <el-option v-for="(item, index) in typeObj" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="币种" class="info_item">
          <el-select class="info_input" v-model="params.currency" placeholder="币种">
            <el-option v-for="(item, index) in currencyArr" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <el-form-item label="账户余额" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.balance" :precision="2" :step="0.1" :max="1000000" />
        </el-form-item>
        <el-form-item label="排序" class="info_item">
          <el-input-number class="info_input" v-model="params.sortNum" :step="1" />
        </el-form-item>
        <el-form-item label="状态" class="info_item">
          <el-switch class="info_input" v-model="status" />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="returnPage">返回</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      params: {},
      typeObj: [
        { value: 1, label: "现金" },
        { value: 2, label: "信用卡" },
        { value: 3, label: "储蓄卡" },
        { value: 4, label: "支付宝" },
        { value: 5, label: "公积金" },
        { value: 6, label: "理财" },
        { value: 7, label: "蚂蚁花呗" },
        { value: 8, label: "微信支付" },
      ],
      currencyArr: ["人民币", "美金", "欧元", "港币", "日元", "英镑"],
      indexType: "",
      status: false,
    };
  },
  methods: {
    onSubmit() {
      this.$confirm("是否确定修改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = this.params;
        param.status = this.status ? 1 : -1;
        param.type = this.indexType;
        param.currency = this.currencyArr.indexOf(param.currency)+1;
        console.log(param.currency);
        this.$api.acc.accEdit(param).then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: "保存成功" });
            setTimeout(() => {
              this.returnPage();
            }, 500);
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    },
    returnPage() {
      this.$router.push({
        path: "/account",
        name: "Account",
      });
    },
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.params = this.$route.params;
    this.status = this.params.status == 1 ? true : false;
    this.indexType = this.params.type;
  },
};
</script>