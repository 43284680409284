<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/account', params:params, name:'Account' }">账户管理</el-breadcrumb-item>
      <el-breadcrumb-item>新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card :body-style="{ padding: '0px' }" shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>添加新账户</span>
      </div>
      <el-form ref="ruleForm" class="info_body" :rules="rules" :model="ruleForm">
        <el-form-item label="账户名" prop="name" class="info_item">
          <el-input v-model="ruleForm.name" placeholder="账户名" class="info_input" clearable />
        </el-form-item>
        <el-form-item v-if="ruleForm.indexType > 0" label="账号" prop="account" class="info_item">
          <el-input v-model="ruleForm.account" placeholder="账号" class="info_input" clearable />
        </el-form-item>
        <el-form-item label="账户类型" prop="indexType" class="info_item">
          <el-select v-model="ruleForm.indexType" placeholder="账户类型" class="info_input">
            <el-option v-for="item in typeObj" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.indexType == 2 || ruleForm.indexType == 7" label="信用额度" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="ruleForm.creditLines" :precision="2" :step="1" :max="1000000" clearable />
        </el-form-item>
        <el-form-item v-if="ruleForm.indexType == 2" label="账单日" class="info_item">
          <el-select v-model="ruleForm.statementDate" class="info_input">
            <el-option v-for="index of 31" :key="index" :label="index" :value="index" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.indexType == 2 || ruleForm.indexType == 7" label="还款日" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="ruleForm.repaymentDate" :step="1" :max="30" clearable />
        </el-form-item>
        <el-form-item v-if="ruleForm.indexType == 2" label="有效期" prop="termOfValidity" class="info_item">
          <el-input class="info_input" v-model="ruleForm.termOfValidity" placeholder="卡片有效期" clearable />
        </el-form-item>
        <el-form-item v-if="ruleForm.indexType == 2" label="签名" prop="signature" class="info_item">
          <el-input class="info_input" v-model="ruleForm.signature" placeholder="签名" clearable />
        </el-form-item>
        <el-form-item label="币种" prop="currency" class="info_item">
          <el-select class="info_input" v-model="ruleForm.currency" placeholder="币种">
            <el-option v-for="(item, index) in currencyArr" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <el-form-item label="账户余额" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="ruleForm.balance" :precision="2" :step="0.1" :max="1000000" clearable />
        </el-form-item>
        <el-form-item label="状态" class="info_item">
          <el-switch class="info_input" v-model="ruleForm.status" />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit('ruleForm')">保存</el-button>
      <el-button @click="returnPage">返回</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      params: {},
      ruleForm: {
        name: "",
        account: "",
        indexType: 1,
        status: true,
        currency: 0,
        balance: 0,
        creditLines: 0,
        statementDate: 1,
        repaymentDate: 20,
        termOfValidity: "",
        signature: "",
      },
      typeObj: [
        { value: 1, label: "现金" },
        { value: 2, label: "信用卡" },
        { value: 3, label: "储蓄卡" },
        { value: 4, label: "支付宝" },
        { value: 5, label: "公积金" },
        { value: 6, label: "理财" },
        { value: 7, label: "蚂蚁花呗" },
        { value: 8, label: "微信支付" },
      ],
      options: [
        { value: -1, label: "不可用" },
        { value: 1, label: "正常" },
      ],
      currencyArr: ["人民币", "美金", "欧元", "港币", "日元", "英镑"],
      rules: {
        name: [
          { min: 2, max: 15, message: "长度在2到15个字符", trigger: "blur" },
        ],
        account: [
          { min: 10, max: 50, message: "长度在10到50个字符", trigger: "blur" },
        ],
        termOfValidity: [
          {
            pattern: /^(0[1-9]|1[0-2])\/[0-9]{2}$/,
            message: "请输入格式为'MM/YY'的卡片有效期",
            trigger: "blur",
          },
        ],
        signature: [
          {
            pattern: /^\d{3}$/,
            message: "请输入三位纯数字签名",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.doSave();
        } else {
          return false;
        }
      });
    },
    doSave() {
      this.$confirm("是否确定提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          name: this.ruleForm.name,
          account: this.ruleForm.account,
          type: this.ruleForm.indexType,
          status: this.ruleForm.status ? 1 : -1,
          currency: this.ruleForm.currency,
          balance: this.ruleForm.balance,
        };
        if (param.type == 2) {
          //信用卡
          param.creditLines = this.ruleForm.creditLines;
          param.statementDate = this.ruleForm.statementDate;
          param.repaymentDate = this.ruleForm.repaymentDate;
          param.termOfValidity = this.ruleForm.termOfValidity;
          param.signature = this.ruleForm.signature;
        }
        if (param.type == 7) {
          //花呗
          param.creditLines = this.ruleForm.creditLines;
          param.repaymentDate = this.ruleForm.repaymentDate;
        }
        this.$api.acc.accAdd(param).then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: "保存成功" });
            setTimeout(() => {
              this.returnPage();
            }, 300);
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    },
    returnPage() {
      this.$router.push({
        path: "/account",
        name: "Account"
      });
    },
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    let params = this.$route.params;
    this.params = params;
    this.ruleForm.indexType = this.params.cond_type;
  },
};
</script>