import { get, post } from '../axios/axios.js'
export default {
    flowPage(params) {
        return post('/adam_book/flow/page', params)
    },
    flowCount(params) {
        return post('/adam_book/flow/count', params)
    },
    flowEdit(params) {
        return post('/adam_book/flow/edit', params)
    },
    flowDel(params) {
        return get('/adam_book/flow/delete/'+params)
    }
}