<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>账户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-select v-model="cond_type" class="table_bar_item" placeholder="请选择账户类型" clearable>
      <el-option v-for="(item, index) in typeObj" :key="index" :label="item.label" :value="item.value" />
    </el-select>
    <el-select v-model="cond_status" class="table_bar_item" placeholder="请选择账户状态" clearable>
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <el-input v-model="filterText" class="table_bar_item fixed_input" placeholder="请输入模糊内容" clearable>
      <el-button slot="append" @click="filterQuery" class="icon_button" icon="el-icon-search" />
    </el-input>
    <el-switch v-model="switchOn" class="table_bar_item" active-text="隐藏空户" />
    <div class="add_btn">
      <!-- 统计净资产 -->
      <el-button v-if="totalMoney" plain type="info" disabled>{{totalMoney}}</el-button>
      <!-- 新增账户 -->
      <el-button plain type="info" @click="handleAdd()">新增</el-button>
    </div>
    <el-table :data="tableData" ref="multipleTable" tooltip-effect="dark" stripe @selection-change="handleSelectionChange" max-height="590">
      <el-table-column type="selection" width="50" fixed />
      <el-table-column type="index" label="#" width="50" />
      <el-table-column prop="type_name" label="账户类型" />
      <el-table-column prop="name" label="账户名" />
      <el-table-column prop="account" label="账号" />
      <el-table-column prop="state" label="状态" />
      <el-table-column prop="currency" label="币种" />
      <el-table-column prop="balance" label="余额" />
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="totalRows" :page-size="size" :current-page.sync="page" @current-change="doPage" @size-change="doSize" :page-sizes="[5, 10, 20]" />
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      filterText: "",
      switchOn: true,
      page: 1,
      size: 10,
      totalMoney: "",
      totalPage: 0,
      totalRows: 0,
      tableData: [],
      multipleSelection: [],
      cond_type: "",
      typeObj: [
        { value: 1, label: "现金" },
        { value: 2, label: "信用卡" },
        { value: 3, label: "储蓄卡" },
        { value: 4, label: "支付宝" },
        { value: 5, label: "公积金" },
        { value: 6, label: "理财" },
        { value: 7, label: "蚂蚁花呗" },
        { value: 8, label: "微信支付" },
      ],
      cond_status: "",
      options: [
        { value: -1, label: "不可用" },
        { value: 1, label: "正常" },
      ],
      currencyArr: ["人民币", "美金", "欧元", "港币", "日元", "英镑"],
    };
  },
  watch: {
    cond_type() {
      this.init();
    },
    cond_status() {
      this.init();
    },
    switchOn() {
      this.init();
    },
    filterText() {
      this.init();
    },
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.init();
    // if (Object.keys(param).length == 0) {
    //   this.init();
    //   return;
    // };
    // if (param.page) {
    //   this.page = param.page;
    //   if (param.cond_type) {
    //     this.cond_type = param.cond_type;
    //     return;
    //   }
    //   this.getCount();
    //   this.getList();
    // }
  },
  methods: {
    init() {
      this.page = 1;
      this.getList();
      this.getCount();
    },
    handleAdd() {
      let params = {
        cond_type: this.cond_type,
        cond_status: this.cond_status
      };
      this.$router.push({
        path: "/accAdd",
        name: "AccAdd",
        params: params,
      });
    },
    handleEdit(index, row) {
      row.cond_type = this.cond_type;
      row.cond_status = this.cond_status;
      row.page = this.page;
      this.$router.push({
        path: "/accDetail",
        name: "AccDetail",
        params: row,
      });
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.doDel(row.id);
        })
        .catch(() => {
          this.$message({ type: "info", message: "已取消" });
        });
    },
    doDel(id) {
      this.$api.acc.accDel(id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: "删除成功" });
          this.getList();
        }
      });
    },
    filterQuery() {
      // if (!this.filterText) return;
      this.getList();
    },
    doPage(currentPage) {
      this.page = currentPage;
      this.getList();
    },
    doSize(currentSize) {
      this.page = 1;
      this.size = currentSize;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    setParams() {
      let params = {
        page: this.page,
        num: this.size,
      };
      if (this.filterText) params.filterText = this.filterText;
      if (this.cond_type) params.type = this.cond_type;
      if (this.cond_status) params.status = this.cond_status;
      if (this.switchOn) params.switchOn = this.switchOn;
      return params;
    },
    doAxios(params) {
      this.tableData = [];
      this.$api.acc.accPage(params).then((res) => {
        if (res.code == 0) {
          let data = res.data;
          this.totalPage = data.totalPage;
          if (this.totalPage > 0) {
            let array = data.list;
            this.totalRows = data.totalNum;
            array.forEach((element) => {
              element.balance = element.balance.toFixed(2) + "";
              element.type_name = this.typeObj.filter((item) => {
                return item.value == element.type;
              })[0].label;
              element.state = this.options.filter((item) => {
                return item.value == element.status;
              })[0].label;
              element.currency = this.currencyArr[element.currency];
              this.tableData.push(element);
            });
          }
        }
      });
    },
    getCount(params) {
      let param = !params ? this.setParams() : params;
      this.$api.acc.accCount(param).then((res) => {
        if (res.code == 0) {
          this.totalMoney = "￥" + res.data.toFixed(2);
        }
      });
    },
    getList() {
      let params = this.setParams();
      this.doAxios(params);
    },
  },
};
</script>