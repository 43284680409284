import { get, post } from '../axios/axios.js'
export default {
    iotPage(params) {
        return post('/adam_device/iot/page', params)
    },
    iotPwdPage(params) {
        return post('/adam_device/iot/pwd/page', params)
    },
    iotPwdDel(params) {
        return post('/adam_device/iot/pwd/remove', params)
    },
    iotPwdFreeze(params) {
        return post('/adam_device/iot/pwd/freeze', params)
    },
    iotPwdThaw(params) {
        return post('/adam_device/iot/pwd/thaw', params)
    },
    iotAttr(params) {
        return get('/adam_device/iot/attr/' + params)
    },
    iotReg(params) {
        return post('/adam_device/iot/register', params)
    },
    iotSend(params) {
        return post('/adam_device/iot/distribute', params)
    },
    iotRemove(params) {
        return get('/adam_device/iot/remove/' + params)
    },
    iotUnlockPage(params) {
        return post('/adam_device/iot/unlock/page', params)
    },
    iotBatchDel(params) {
        return post('/adam_device/iot/batch/del', params)
    },
    iotBatchFreeze(params) {
        return post('/adam_device/iot/batch/freeze', params)
    },
    iotBatchThaw(params) {
        return post('/adam_device/iot/batch/thaw', params)
    }
}