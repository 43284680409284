<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/credit'}">信用卡中心</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card :body-style="{ padding: '0px' }" shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>信用卡中心</span>
      </div>
      <el-form ref="form" class="info_body" :model="params">
        <el-form-item label="卡片名称" class="info_item">
          <el-input v-model="params.cardName" class="info_input" disabled />
        </el-form-item>
        <el-form-item label="卡号" class="info_item">
          <el-input v-model="params.cardNum" class="info_input" disabled />
        </el-form-item>
        <el-form-item label="信用额度" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.creditLines" :precision="2" :step="0.1" :max="1000000" />
        </el-form-item>
        <el-form-item label="可用额度" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.leftAmount" disabled />
        </el-form-item>
        <el-form-item label="未出账单" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.unpaidBill" :precision="2" :step="0.1" :max="1000000" />
        </el-form-item>
        <el-form-item label="已出账单" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.repayAmount" :precision="2" :step="0.1" :max="1000000" />
        </el-form-item>
        <el-form-item label="账单日" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.statementDate" :step="1" :max="30" />
        </el-form-item>
        <el-form-item label="距出账日" class="info_item">
          <el-input-number class="info_input" controls-position="right" v-model="params.repaymentDate" :step="1" :max="30" />
        </el-form-item>
        <el-form-item label="签名栏" class="info_item">
          <el-input v-model="params.signature" class="info_input" />
        </el-form-item>
        <el-form-item label="有效期" class="info_item">
          <el-input v-model="params.termOfValidity" class="info_input" />
        </el-form-item>
        <el-form-item label="账单发送地址" class="info_item">
          <el-input v-model="params.billSender" class="info_input" />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="returnPage">返回</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      params: {}
    };
  },
  methods: {
    onSubmit() {
      this.$confirm("是否确定修改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.params.repayAmount = this.params.repayAmount.toFixed(2);
        this.$api.user.creditEdit(this.params).then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: "保存成功" });
            setTimeout(() => {
              this.returnPage();
            }, 500);
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    },
    returnPage() {
      this.$router.push({
        path: "/credit",
        name: "Credit"
      });
    },
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.params = this.$route.params;
  },
};
</script>