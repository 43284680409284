<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="contents">
      <el-card shadow="always" class="info_card info_table">
        <div id="brokenline_main" />
      </el-card>
      <el-card shadow="always" class="info_card info_column">
        <div id="columnar_main" />
      </el-card>
      <el-card shadow="always" class="info_card">
        <div id="annular_main" />
      </el-card>
    </div>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      option1: {
        title: {
          text: '日支出统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['支出金额']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '支出金额',
          type: 'line',
          stack: '总金额',
          data: []
        }]
      },
      option2: {
        title: {
          text: '月收支汇总'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { 
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '账单总额',
          type: 'bar',
          barWidth: '60%',
          data: []
        }]
      },
      option3: {
        title: {
          text: '支出分类占比'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: 'left'
        },
        series: [{
          name: '分类名称',
          type: 'pie',
          radius: ['30%', '70%'],
          avoidLabelOverlap: false,
          center: ['60%', '50%'],
          label: {
            show: false,
            position: 'left'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '16',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }]
      }
    }
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.getBrokenline();
    this.getColumnar();
    this.getAnnular();
  },
  methods: {
    getBrokenline() {
      this.$api.report.reportBroken().then(res => {
        if (res.code == 0) {
          res.data.forEach(item => {
            this.option1.xAxis.data.push(item.name.slice(5));
            this.option1.series[0].data.push(item.money);
          });
          let myChart1 = this.$echarts.init(document.getElementById('brokenline_main'), 'light');
          myChart1.setOption(this.option1);
          myChart1.on('click', a => {
            this.$router.push({
              path: "/flow",
              name: "Flow",
              params: {
                type: 'broken',
                timeRange: a.name
              }
            });
          });
        }
      });
    },
    getColumnar() {
      this.$api.report.reportColumnar().then(res => {
        if (res.code == 0) {
          res.data.forEach(item => {
            this.option2.xAxis.data.push(item.name);
            this.option2.series[0].data.push(item.money);
          });
          let myChart2 = this.$echarts.init(document.getElementById('columnar_main'), 'light');
          myChart2.setOption(this.option2);
          myChart2.on('click', a => {
            this.$router.push({
              path: "/flow",
              name: "Flow",
              params: {
                type: 'columnar',
                timeRange: a.name
              }
            });
          });
        }
      });
    },
    getAnnular() {
      this.$api.report.reportAnnular().then(res => {
        if (res.code == 0) {
          res.data.forEach(item => {
            let obj = {
              name: item.name,
              value: item.money
            };
            this.option3.series[0].data.push(obj);
          });
          let myChart3 = this.$echarts.init(document.getElementById('annular_main'), 'light');
          myChart3.setOption(this.option3);
          myChart3.on('click', a => {

          });
        }
      });
    }
  }
}
</script>
<style scoped>
#brokenline_main {
  height: 400px !important;
}
#columnar_main,
#annular_main {
  height: 300px !important;
}
#contents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
#contents >>> .el-card__body {
  padding: 0 !important;
}
.info_card {
  height: 300px;
  padding: 15px;
  flex-basis: 25%;
}
.info_column {
  flex-basis: 67% !important;
}
.info_table {
  height: 400px;
  flex-basis: 96%;
  margin-bottom: 10px;
}
</style>