<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == 0" :to="{ path: '/cateExpend' }">支出分类</el-breadcrumb-item>
      <el-breadcrumb-item v-else :to="{ path: '/cateIncome' }">收入分类</el-breadcrumb-item>
      <el-breadcrumb-item>新增</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card :body-style="{ padding: '0px' }" shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>收支分类</span>
      </div>
      <el-form ref="ruleForm" class="info_body" :model="ruleForm">
        <el-form-item label="分类名称" class="info_item">
          <el-input v-model="ruleForm.name" class="info_input" placeholder="分类名称" clearable />
        </el-form-item>
        <el-form-item label="排序" class="info_item">
          <el-input-number class="info_input" v-model="ruleForm.seq" :step="1" />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="resetForm">重置</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      type: '',
      ruleForm: {
        name: '',
        seq: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在2到10个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.doSave();
        } else {
          return false;
        }
      });
    },
    doSave() {
      this.$confirm('是否确定提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          cateName: this.ruleForm.name,
          type: this.type,
          pid: 0
        };
        if (this.ruleForm.seq) {
          param.seq = this.ruleForm.seq
        }
        this.$api.cate.cateAdd(param).then(res => {
          if (res.code == 0) {
            this.$message({ type: 'success', message: '保存成功' });
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000);
          }
        });
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消' });
      });
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields();
    },
    returnPage() {
      this.$router.go(-1);
    }
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
    this.type = this.$route.params.type;
  }
}
</script>