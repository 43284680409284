<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card shadow="always" class="info_card">
      <div slot="header" class="clearfix info_header">
        <span>修改密码</span>
        <el-button class="right_btn" type="text" @click="resetForm">清空</el-button>
      </div>
      <el-form ref="form" class="info_body" :model="form" :rules="rules">
        <el-form-item label="旧密码" prop="password" class="info_item">
          <el-input v-model="form.password" placeholder="请输入旧密码" class="info_input" show-password clearable />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword" class="info_item">
          <el-input v-model="form.newPassword" placeholder="请输入新密码" class="info_input" show-password clearable />
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirm" class="info_item confirm">
          <el-input v-model="form.confirm" placeholder="请再次输入新密码" class="info_input" show-password clearable />
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="always" class="info_card">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="resetForm">重置</el-button>
    </el-card>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      form: {
        password: "",
        newPassword: "",
        confirm: "",
      },
      rules: {
        password: [
          { required: true, message: "旧密码不能为空", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { min: 6, max: 30, message: "新密码长度为6-30个字符", trigger: "blur" },
        ],
        confirm: [
          { required: true, message: "确认新密码不能为空", trigger: "blur" },
        ]
      },
    }
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //确认新密码
          if (this.form.newPassword != this.form.confirm) {
            this.$message({ type: "error", message: "两次密码输入不一致" });
            return false;
          }
          this.doSave();
        } else {
          return false;
        }
      });
    },
    logout() {
      this.$api.user.logout().then((res) => {
        if (res.code == 0) {
          localStorage.clear();
          this.$router.push("/");
        }
      });
    },
    doSave() {
      this.$confirm("是否确定提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          password: this.$md5(this.form.password + "www.yfx1688.com"),
          newPassword: this.$md5(this.form.newPassword + "www.yfx1688.com")
        };
        this.$api.user.userPwd(params).then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: "修改成功" });
            this.logout();
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "已取消" });
      });
    }
  }
}
</script>
<style scoped>
.info_card {
  padding: 15px;
}
.info_card:not(:last-child) {
  height: 120px;
  margin-bottom: 20px;
}
.info_card >>> .el-card__body {
  padding: 0;
}
.info_header {
  height: 45px !important;
}
/* .info_header span {
  line-height: 45px;
} */
.info_body {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.info_item {
  width: 33%;
  display: block;
  margin-bottom: 0;
}
.info_input {
  float: left;
  width: 255px;
}
.info_item >>> .el-form-item__error {
  margin-left: 75px;
}
.confirm >>> .el-form-item__error {
  margin-left: 100px;
}
</style>