import { get, post } from '../axios/axios.js'
export default {
    accAdd(params) {
        return post('/adam_book/account/add', params)
    },
    accEdit(params) {
        return post('/adam_book/account/edit', params)
    },
    accDel(params) {
        return get('/adam_book/account/del/' + params)
    },
    accPage(params) {
        return post('/adam_book/account/page', params)
    },
    accCount(params) {
        return post('/adam_book/account/count', params)
    },
    accSelect() {
        return get('/adam_book/account/select')
    }
}