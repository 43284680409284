import { get, post } from '../axios/axios.js'
export default {
    cateCascade(params) {
        return get('/adam_book/cate/cascade/' + params)
    },
    cateDel(params) {
        return post('/adam_book/cate/del', params)
    },
    cateEdit(params) {
        return post('/adam_book/cate/edit', params)
    },
    cateAdd(params) {
        return post('/adam_book/cate/add', params)
    },
    catePage(params) {
        return post('/adam_book/cate/page', params)
    },
    cateLazy(params) {
        return get('/adam_book/cate/lazy/' + params)
    },
    cateParents(params) {
        return get('/adam_book/cate/parents/' + params)
    }
}