<template>
  <el-aside>
    <el-menu class="el-menu-vertical-demo" :default-active="$route.path" @select="onSelect">
      <el-menu-item v-for="(item, index) in menus" :key="index" :index="item.index">
        <i :class="item.icon" />
        <span slot="title" v-text="item.title" />
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        {
          index: "/index",
          icon: "el-icon-menu iconfont el-icon-index",
          title: "首页",
        },
        {
          index: "/account",
          icon: "el-icon-menu iconfont el-icon-wallet",
          title: "账户管理",
        },
        {
          index: "/flow",
          icon: "el-icon-menu iconfont el-icon-flow",
          title: "明细流水",
        },
        {
          index: "/cateIncome",
          icon: "el-icon-menu iconfont el-icon-incoming",
          title: "收入分类",
        },
        {
          index: "/cateExpend",
          icon: "el-icon-menu iconfont el-icon-expend",
          title: "支出分类",
        },
        {
          index: "/credit",
          icon: "el-icon-menu iconfont el-icon-credit",
          title: "信用卡中心",
        },
        {
          index: "/huabei",
          icon: "el-icon-menu iconfont el-icon-huabei",
          title: "蚂蚁花呗",
        },
      ],
    };
  },
  created() { },
  methods: {
    onSelect(index) {
      this.$router.push(index);
    },
  },
};
</script>
<style>
.active {
  color: #409eff !important;
  background-color: #ecf5ff !important;
}
.active i {
  color: #409eff !important;
}
</style>