<template>
  <div class="login_container">
    <div class="content" id="login">
      <p class="content_p">企瀚财务管理系统</p>
      <el-form ref="form" :model="form" class="content_form">
        <el-form-item>
          <el-input prefix-icon="el-icon-user" v-model="form.name" placeholder="请输入用户名" clearable />
        </el-form-item>
        <el-form-item>
          <el-input prefix-icon="el-icon-lock" v-model="form.password" placeholder="请输入密码" show-password clearable />
        </el-form-item>
        <el-form-item>
          <el-button plain class="content_btn" type="primary" @click="onSubmit">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.$api.user.userOrgs(this.form.name).then((res) => {
          let params = {
            account: this.form.name,
            password: this.$md5(this.form.password + "www.yfx1688.com"),
          };
          if (res.data.length > 0) {
            params.companyId = res.data[0].id
          }
          this.$api.user.login(params).then((res) => {
            if (res.code == 0) {
              localStorage.clear();
              localStorage.setItem("MyToken", res.data.token);
              localStorage.setItem("TrueName", res.data.name);
              this.$api.user.userInfo().then((res) => {
                localStorage.setItem("UserInfo", JSON.stringify(res.data));
              });
              if (this.$route.query.path) {
                this.$router.push(this.$router.query.path);
              } else {
                this.$router.push("/index");
              }
            }
          });
      });
    }
  }
};
</script>
<style scoped>
.login_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(237, 237, 237);
  background-image: url(../assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.content {
  width: 600px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-300px, -200px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.1);
}
.content_p {
  width: 600px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 35px;
  font-family: "楷体";
  font-weight: bolder;
  margin: 0 !important;
  color: #fff;
  text-shadow: 1px 1px 1px lightgray, 6px 4px 1px#aaa;
}
.content_form {
  width: 300px;
  position: relative;
  top: -28px;
}
.content_btn {
  width: 300px;
}
</style>