<template>
  <el-main>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>支出分类</el-breadcrumb-item>
    </el-breadcrumb>
    <el-select v-model="parent" class="table_bar_item" placeholder="请选择父分类" clearable>
      <el-option v-for="item in parents" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
    <el-button plain type="info" class="right_btn" @click="handleAdd()">新增</el-button>
    <el-table ref="table" :data="tableData" row-key="id" v-loading="loading" @row-click="rowClick" max-height="600">
      <el-table-column type="selection" width="55" fixed />
      <el-table-column prop="name" label="分类名称" />
      <el-table-column prop="number" label="分类编码" />
      <!-- <el-table-column prop="level" label="级别" /> -->
      <el-table-column prop="seq" label="排序" />
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="addChild(scope.row)" v-if="scope.row.pid == 0">添加</el-button>
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      parent: '',
      tableData: [],
      parents: []
    };
  },
  created() {
    this.getList();
    this.getParents();
  },
  mounted() {
    let trueName = localStorage.getItem("TrueName");
    this.$bus.$emit('myMsg', trueName);
  },
  watch: {
    parent() {
      this.getList();
    }
  },
  methods: {
    rowClick(row, event, column) {
      if (column.target.nodeName != "DIV") return;
      this.$nextTick(() => {
        row.isExpand = !row.isExpand;
        this.$refs.table.toggleRowExpansion(row, row.isExpand);
      });
    },
    addChild(row) {
      if (!row) return;
      this.$prompt("请输入子分类名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        if (!value) return;
        let param = {
          cateName: value,
          type: 0,
          pid: row.id,
        };
        this.$api.cate.cateAdd(param).then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: "保存成功" });
            this.getList();
          }
        });
      }).catch(() => {
        this.$message({ type: "info", message: "取消输入" });
      });
    },
    handleAdd() {
      this.$router.push({
        path: "/categoryAdd",
        name: "CategoryAdd",
        params: {
          type: 0,
        },
      });
    },
    handleEdit(row) {
      row.type = 0;
      this.$router.push({
        path: "/categoryEdit",
        name: "CategoryEdit",
        params: row,
      });
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该分类及其子分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.doDel(row);
      }).catch(() => {
        this.$message({ type: "info", message: "已取消删除" });
      });
    },
    doDel(row) {
      let param = {
        cateName: row.name,
        type: 0,
        seq: row.seq,
        pid: row.pid,
        id: row.id,
      };
      this.$api.cate.cateDel(param).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: "删除成功" });
          this.getList();
        }
      });
    },
    getParents() {
      this.$api.cate.cateParents(0).then(res => {
        if (res.data) {
          this.parents = res.data;
        }
      });
    },
    getList() {
      this.loading = true;
      this.tableData = [];
      let param = { type: 0 };
      if (this.parent) param.pid = this.parent;
      this.$api.cate.catePage(param).then((res) => {
        this.loading = false;
        if (res.data) {
          this.tableData = res.data;
          this.tableData.forEach((item) => {
            item.isExpand = false;
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.table_bar_item {
  margin-left: 0 !important;
}
</style>