import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Index from '../views/Index'
import Account from '../views/Account'
import AccDetail from '../views/AccDetail'
import AccAdd from '../views/AccAdd'
import Credit from '../views/Credit'
import CreditDetail from '../views/CreditDetail'
import Huabei from '../views/Huabei'
import HuabeiDetail from '../views/HuabeiDetail'
import CateExpend from '../views/CateExpend'
import CategoryEdit from '../views/CategoryEdit'
import CategoryAdd from '../views/CategoryAdd'
import CateIncome from '../views/CateIncome'
import Flow from '../views/Flow'
import PwdUpd from '../views/PwdUpd'
import PersonalCenter from '../views/PersonalCenter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/pwdupd',
    name: 'PwdUpd',
    component: PwdUpd
  },
  {
    path: '/personal',
    name: 'PersonalCenter',
    component: PersonalCenter
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/accDetail',
    name: 'AccDetail',
    component: AccDetail
  },
  {
    path: '/credit',
    name: 'Credit',
    component: Credit
  },
  {
    path: '/creditDetail',
    name: 'CreditDetail',
    component: CreditDetail
  },
  {
    path: '/huabei',
    name: 'Huabei',
    component: Huabei
  },
  {
    path: '/huabeiDetail',
    name: 'HuabeiDetail',
    component: HuabeiDetail
  },
  {
    path: '/accAdd',
    name: 'AccAdd',
    component: AccAdd
  },
  {
    path: '/cateExpend',
    name: 'CateExpend',
    component: CateExpend
  },
  {
    path: '/categoryEdit',
    name: 'CategoryEdit',
    component: CategoryEdit
  },
  {
    path: '/categoryAdd',
    name: 'CategoryAdd',
    component: CategoryAdd
  },
  {
    path: '/cateIncome',
    name: 'CateIncome',
    component: CateIncome
  },
  {
    path: '/flow',
    name: 'Flow',
    component: Flow
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//vue前置守卫/路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(r => r.meta.requiresAuth)) {
    if (localStorage.getItem('UserInfo')) {
      next();
    } else {
      router.push({ path: '/' });
    }
  } else {
    next();
  }
})

export default router
