<template>
  <el-header>
    <el-image class="header_logo" :src="logo" :fit="fill"></el-image>
    <h1 class="header_title">企瀚财务管理系统</h1>
    <el-dropdown @command="handleCommand">
      <i class="el-icon-switch-button" style="margin-right: 15px"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="a">个人中心</el-dropdown-item>
        <el-dropdown-item command="b">修改密码</el-dropdown-item>
        <el-dropdown-item command="c">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <span>{{trueName}}</span>
  </el-header>
</template>
<script>
export default {
  data() {
    return {
      trueName: "",
      logo: "http://file.cheham.asia/logo.png"
    };
  },
  mounted() {
    this.$bus.$on('myMsg', myMsg => {
      this.trueName = myMsg;
    });
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "a":
          this.$router.push("/personal");
          break;
        case "b":
          this.$router.push("/pwdupd");
          break;
        case "c":
          this.logout();
          break;
      }
    },
    logout() {
      this.$api.user.logout().then((res) => {
        if (res.code == 0) {
          localStorage.clear();
          this.$router.push("/");
        }
      });
    }
  }
};
</script>
<style>
.header_title {
  margin: 0;
  margin-left: 10px;
  color: #fff;
  float: left;
}
.header_logo {
  float: left;
  top: 6px;
}
</style>