import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'

import { Message } from 'element-ui'

// 响应时间
axios.defaults.timeout = 5 * 1000

// 配置cookie
axios.defaults.withCredentials = false

// 配置请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 静态资源
Vue.prototype.$static = ''

// 配置接口地址
axios.defaults.baseURL = 'http://cheham.asia'
// axios.defaults.baseURL = 'http://43.153.94.45'

// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
    config => {
        let headers = store.state.headers;
        Object.keys(headers).forEach(key => {
            config.headers[key] = headers[key]
        });
        config.headers['MyToken'] = localStorage.getItem('MyToken')
        return config
    }, err => {
        Message.error('请求错误')
        return Promise.reject(err)
    }
)

// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
    res => {
        let data = res.data;
        switch (data.code) {
            case 8010:
                Message.error(data.message);
                localStorage.clear();
                router.push('/');
                break;
            case -1:
                Message.error(data.message);
                break;
            case 0:
            case 200:
            case 500:
                break;
            default:
                Message.error('程序异常');
                break;
        }
        return res
    }, err => {
        Message.error('请求失败，请稍后再试')
        return Promise.reject(err)
    }
)

// 发送请求
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(
            res => {
                resolve(res.data)
            },
            err => {
                reject(err.data)
            }
        ).catch(err => {
            console.log(err);
        })
    })
}

export function get(url) {
    return new Promise((resolve, reject) => {
        axios.get(url).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.log(err);
        })
    })
}